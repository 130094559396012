import React, { useEffect } from "react";
import "../App.css";

import screen1 from "../assets/Screenshot/screen1.jpeg";
import screen2 from "../assets/Screenshot/screen2.jpeg";
import screen3 from "../assets/Screenshot/screen3.jpeg";
import screen4 from "../assets/Screenshot/screen4.jpeg";
import screen5 from "../assets/Screenshot/screen5.jpeg";
import screen6 from "../assets/Screenshot/screen6.jpeg";
import PaymentCard from "./Components/PaymentCard";

import "./CSS/register.css";

function Home({ history }) {
  useEffect(() => {}, []);
  return (
    <div className="App">
      <div className="page1">
        {/* <div className="homeText">
          In order to subscribe; login on the right, go to account and update
          payment details and subscription
          <br />
          <br />
          Please make sure to update to the latest version on both iOS and
          Android
        </div> */}
        <div className="Screenshots">
          <img src={screen1} alt="Screenshot"></img>
          <img src={screen2} alt="Screenshot"></img>
          <img src={screen3} alt="Screenshot"></img>
          <img src={screen4} alt="Screenshot"></img>
          <img src={screen5} alt="Screenshot"></img>
          <img src={screen6} alt="Screenshot"></img>
        </div>
        <div className="homeText">
          <ul className="list">
            <li>Click banners to 'Reveal' the answer</li>
            <li>Over 300 topics covering the Final FRCA </li>
            <li>
              Over 180 Primary FRCA topics, plus every OSCE topic we could think
              of!
            </li>
          </ul>
          <ul className="list">
            <li>Test yourself with 'revision mode'</li>
            <li>Get a friend to viva you with 'viva mode'</li>
            <li>Keep a check on your progress</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Home;
