import React, { useState, useEffect, useCallback } from "react";
import { TextField, Button, Divider } from "@material-ui/core";
import "./CSS/register.css";
import logo from "../assets/logo/logo.png";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
import { Link } from "react-router-dom";
import Cookies, { set } from "js-cookie";
import CardSection from "./Components/CardSection";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentCard from "./Components/PaymentCard";
import Papa from "papaparse";
import moment from "moment";
import { BackendURL } from "../backendURL.js";
// import {
//   Page,
//   Text,
//   View,
//   Document,
//   StyleSheet,
//   ReactPDF,
//   PDFDownloadLink,
// } from "@react-pdf/renderer";
import ErrorBoundary from "./Components/ErrorBoundary.js";

export default function Login({ history }) {
  let enteredCode = "";

  const elements = useElements();
  const stripe = useStripe();

  const [percent, setPercent] = useState(0);
  const [promoError, setPromoError] = useState("");
  const [promo, setPromo] = useState("");
  const [promoID, setPromoID] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [text, setText] = useState("");
  const [linkType, setLinkType] = useState(0);
  const [parsedFile, setParsedFile] = useState([]);
  const [emailValid, setEmailValid] = useState(true);
  const [responseArray, setResponseArray] = useState([]);
  const [userArray, setUserArray] = useState([]);
  const [instText, setInstText] = useState();

  const [deleteAccount, setDeleteAccount] = useState();

  const [confirmed, setConfirmed] = useState(false);

  // const styles = StyleSheet.create({
  //   page: {
  //     flexDirection: "column",
  //     backgroundColor: "#E4E4E4",
  //   },
  //   section: {
  //     margin: 10,
  //     padding: 10,
  //     flexGrow: 1,
  //   },
  // });

  const theme = createTheme({
    palette: {
      primary: {
        main: "#2c1951",
      },
      secondary: {
        main: "#f2f2f7",
      },
    },
  });

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const [currentSelected, setCurrentSelected] = useState(0);
  const [payment, setPayment] = useState(false);
  const [updateSub, setUpdateSub] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentFile, setCurrentFile] = useState();
  const [user, setUser] = useState({
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    subType: "",
    cardType: "",
    cardNum: "",
    expDate: "",
    stripeID: "",
    methodID: "",
    endDate: "",
    devices: "",
    type: "",
  });

  useEffect(() => {
    console.log("link");
    setPayment(false);
  }, [linkType]);

  const handlePromo = async (e) => {
    e.preventDefault();
    await axios
      .post(`${BackendURL}/get-promo-discount`, {
        code: enteredCode,
      })
      .then((response) => {
        setPromoID(response.data.id);
        setPercent(response.data.percent);
        setPromoError("Promo Code Applied");
      })
      .catch((err) => {
        setPromoError("Invalid Promo Code");
      });
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  const listenCookieChange = (callback, interval = 1000) => {
    let lastCookie = document.cookie;
    setInterval(() => {
      let cookie = document.cookie;
      if (cookie !== lastCookie) {
        try {
          callback({ oldValue: lastCookie, newValue: cookie });
        } finally {
          lastCookie = cookie;
        }
      }
    }, interval);
  };

  listenCookieChange(({ oldValue, newValue }) => {
    setCurrentSelected(Cookies.get("subType"));
  }, 1000);

  // const UserRenderPDF = userArray.map((u, i) => (
  //   <View style={styles.section}>
  //     <Text>Number - {i}</Text>
  //     <Text>First Name - {u.first_name}</Text>
  //     <Text>Last Name - {u.last_name}</Text>
  //     <Text>Email - {u.email}</Text>
  //     <Text>Sub Type - {u.sub_type}</Text>
  //     <Text>
  //       Status - {String(responseArray[i])} :{" "}
  //       {responseArray[i] == 200
  //         ? "Success"
  //         : responseArray[i] == 409
  //         ? "Success - User Already Exists"
  //         : "Server Error - Contact Admin"}
  //     </Text>
  //   </View>
  // ));

  // const MyDocument = () => (
  //   <Document>
  //     <Page size="A4" style={styles.page}>
  //       {UserRenderPDF}
  //     </Page>
  //   </Document>
  // );

  const RenderPayment = () => {
    const handlePayment = async () => {
      if (!payment) {
        setPayment(true);
      }
      if (!payment) {
        setPayment(true);
      } else {
        const card = elements.getElement(CardElement);

        const result = await stripe.createToken(card);
        axios
          .post(`${BackendURL}/update-payment-method`, {
            stripeID: user.stripeID,
            token: result.token.id,
          })
          .then((response) => {
            getUser();
            setPayment(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    //render payment update
    if (payment) {
      return (
        <div>
          <Divider light={false} variant="fullWidth" />
          <ErrorBoundary>
            <CardSection />
          </ErrorBoundary>
          <div>
            <h6>{errorMessage}</h6>
          </div>

          <div className="payment-text">
            <ThemeProvider theme={theme}>
              <div className="inlineButton">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handlePayment}
                  margin="small"
                  size="small"
                  fullWidth
                >
                  Update
                </Button>
              </div>
            </ThemeProvider>
          </div>
        </div>
      );
    } else {
      return (
        <div className="payment-text">
          <ThemeProvider theme={theme}>
            <div className="inlineButton">
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePayment}
                margin="small"
                size="small"
                fullWidth
              >
                Update
              </Button>
            </div>
          </ThemeProvider>
        </div>
      );
    }
  };

  const RenderSub = () => {
    const handleSub = async () => {
      setText("Loading...");
      setButtonEnabled(false);
      console.log(user);
      if (currentSelected && currentSelected > 0) {
        axios
          .get(`${BackendURL}/list-payment-method`, {
            headers: {
              authorization: `Bearer ${getCookie("accessToken")}`,
            },
          })
          .then((paymentMethodObject) => {
            if (paymentMethodObject.data.data[0]) {
              axios
                .post(`${BackendURL}/get-price`, {
                  discount: percent,
                  months: currentSelected,
                })
                .then((priceData) => {
                  axios
                    .post(`${BackendURL}/create-payment-intent`, {
                      price: priceData.data.price,
                      stripeID: user.stripeID,
                    })
                    .then((paymentIntent) => {
                      stripe
                        .confirmCardPayment(paymentIntent.data.client_secret, {
                          payment_method: paymentMethodObject.data.data[0].id,
                        })
                        .then((result) => {
                          if (result.paymentIntent) {
                            if (result.paymentIntent.status === "succeeded") {
                              axios
                                .post(`${BackendURL}/update-sub`, {
                                  subType: currentSelected,
                                  stripeID: user.stripeID,
                                })
                                .then((response) => {
                                  setText("Payment Successful");
                                })
                                .catch((e) => {
                                  setText("error");
                                  console.log(e);
                                });
                            }
                          } else {
                            console.log(result.error);
                            setText(result.error.message);
                            setButtonEnabled(true);
                          }
                        });
                    });
                });
            } else {
              setText("<====  Please Update Payment Details");
              setButtonEnabled(true);
            }
          });
      } else {
        setText("Please select a package");
        setButtonEnabled(true);
      }
    };
    if (updateSub) {
      return (
        <div>
          <div className="payment-cards">
            <PaymentCard
              months={1}
              price={"£" + parseFloat(16 * (1 - percent / 100)).toFixed(2)}
            />
            <PaymentCard
              months={2}
              price={"£" + parseFloat(26 * (1 - percent / 100)).toFixed(2)}
            />
            <PaymentCard
              months={3}
              price={"£" + parseFloat(36 * (1 - percent / 100)).toFixed(2)}
            />
            <PaymentCard
              months={6}
              price={"£" + parseFloat(50 * (1 - percent / 100)).toFixed(2)}
            />
            <PaymentCard
              months={12}
              price={"£" + parseFloat(80 * (1 - percent / 100)).toFixed(2)}
            />
            <PaymentCard
              months={24}
              price={"£" + parseFloat(120 * (1 - percent / 100)).toFixed(2)}
            />
          </div>
          <Divider />
          <b>
            {currentSelected && currentSelected != 0
              ? "You have selected the " + currentSelected + " month package"
              : "You have not selected a package"}{" "}
          </b>
          <br />
          <form>
            <TextField
              id="promo"
              label="Promo Code"
              variant="outlined"
              margin="normal"
              fullWidth
              onChange={(e) => {
                enteredCode = e.target.value;
                console.log(enteredCode);
                //setPromo(e.target.value);
              }}
            />
            <h5>{promoError}</h5>
            <ThemeProvider theme={theme}>
              <div className="inlineButton">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handlePromo}
                  margin="small"
                  size="small"
                >
                  Apply
                </Button>
              </div>
            </ThemeProvider>
          </form>
          <br />
          <br />

          <div className="payment-text">
            <ThemeProvider theme={theme}>
              <div className="inlineButton">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSub}
                  margin="small"
                  size="small"
                  fullWidth
                  disabled={!buttonEnabled}
                >
                  Update
                </Button>
              </div>
            </ThemeProvider>
            {text}
          </div>
        </div>
      );
    } else {
      return (
        <div className="payment-text">
          <ThemeProvider theme={theme}>
            <div className="inlineButton">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setUpdateSub(true);
                }}
                margin="small"
                size="small"
                fullWidth
              >
                Update
              </Button>
            </div>
          </ThemeProvider>
        </div>
      );
    }
  };

  const handleLogout = () => {
    Cookies.remove("accessToken");

    history.push({ pathname: "/" });
    window.location.reload();
  };

  const getUser = async () => {
    await axios
      .get(`${BackendURL}/loginToken`, {
        headers: {
          authorization: `Bearer ${getCookie("accessToken")}`,
        },
      })
      .then(async (userResponse) => {
        await axios
          .get(`${BackendURL}/list-payment-method`, {
            headers: {
              authorization: `Bearer ${getCookie("accessToken")}`,
            },
          })
          .then(async (pmList) => {
            await axios
              .get(`${BackendURL}/storeToken`, {
                headers: {
                  authorization: `Bearer ${getCookie("accessToken")}`,
                },
              })
              .then((deviceResponse) => {
                console.log(userResponse);
                const date = moment(
                  userResponse.data.user.createDate,
                  "YYYY-MM-DD"
                );
                const endDate = date.add(
                  userResponse.data.user.subType * 30,
                  "days"
                );
                console.log(userResponse);
                setUser({
                  id: userResponse.data.user.id,
                  email: userResponse.data.user.email,
                  firstName: userResponse.data.user.firstName,
                  lastName: userResponse.data.user.lastName,
                  subType: userResponse.data.user.subType,
                  cardType:
                    pmList.data.data[0] != null
                      ? pmList.data.data[0].card.brand
                      : "",
                  cardNum:
                    pmList.data.data[0] != null
                      ? "****" + pmList.data.data[0].card.last4
                      : "No Card Exists Please Update",
                  stripeID: userResponse.data.user.stripeID,
                  methodID:
                    pmList.data.data[0] != null ? pmList.data.data[0].id : "",
                  expDate:
                    pmList.data.data[0] != null
                      ? pmList.data.data[0].card.exp_month +
                        "/" +
                        pmList.data.data[0].card.exp_year
                      : " ",
                  endDate: endDate.format("DD/MM/YYYY"),
                  devices: deviceResponse.data.devices,
                  type: userResponse.data.user.type,
                });
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch(async (err) => {
        if (err) {
          setUser({
            email: "",
            firstName: "Too many Requests please try again later",
            lastName: "",
            subType: "",
            cardType: "",
            cardNum: "",
            expDate: "",
            stripeID: "",
            methodID: "",
            endDate: "",
            devices: "",
          });
        }
      });
  };

  useEffect(() => {
    getUser();
    Cookies.set("subType", 0);
  }, []);

  const handleDeviceLogout = async (index) => {
    await axios
      .post(
        `${BackendURL}/logout`,
        {
          id: user.id,
          token: user.devices[index],
        },
        {
          headers: {
            authorization: `Bearer ${getCookie("accessToken")}`,
          },
        }
      )
      .then((response) => {
        window.location.reload();
      });
  };
  const RenderPreview = (props) => {
    if (!validateEmail(props.email)) {
      setEmailValid(false);
    }

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          backgroundColor: "#f2f2f7",
          padding: 20,
          borderRadius: 10,
          marginTop: 30,
        }}
      >
        <div style={{ textAlign: "center", flexGrow: 1 }}>
          {props.firstName}
        </div>
        <div style={{ textAlign: "center", flexGrow: 1 }}>{props.lastName}</div>
        <div
          style={
            validateEmail(props.email)
              ? { textAlign: "center", flexGrow: 1 }
              : { textAlign: "center", flexGrow: 1, color: "red" }
          }
        >
          {props.email}
        </div>
        <div style={{ textAlign: "center", flexGrow: 1 }}>{props.password}</div>
      </div>
    );
  };

  let creationError = false;

  const renderFile = parsedFile.map((e, i) => {
    console.log(e);

    if (responseArray[i] && responseArray[i] != 200) {
      creationError = true;
    }

    return (
      <RenderPreview
        firstName={e.first_name}
        lastName={e.last_name}
        email={
          responseArray[i] == 409
            ? e.email + " - Email already exists"
            : e.email
        }
        password={e.password}
      />
    );
  });

  const renderConfirm = () => {
    if (confirmed && emailValid) {
      return (
        <div style={{ width: "300px", marginTop: 20 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              setInstText("Loading... This may take a few minutes");
              await axios
                .post(`${BackendURL}/uploadJSON`, {
                  upload: parsedFile,
                })
                .then((response) => {
                  setInstText();
                  console.log("ACCOUNT");
                  console.log(response);
                  setUserArray(parsedFile);
                  setResponseArray(response.data);
                  setEmailValid(true);
                })
                .catch((res) => {
                  console.log("CAUGHT");
                  console.log(res);
                });
            }}
            margin="small"
            size="small"
            fullWidth
          >
            CREATE ACCOUNTS
          </Button>
        </div>
      );
    } else if (!emailValid) {
      return <h3>Emails invalid please review</h3>;
    } else {
      return null;
    }
  };

  return (
    <div className="fullBody">
      <div className="mainAccountContainer">
        {/* <img src={logo} alt="Logo" className="logo"></img> */}
        <form className="accountContainer">
          {/* SideBar */}

          <div className="sideBar">
            <div className="navBar">
              <ul>
                <li>
                  <h4
                    className="accountBar"
                    style={{ fontWeight: linkType == 0 ? "normal" : 300 }}
                    onClick={() => {
                      setLinkType(0);
                    }}
                  >
                    Account Details
                  </h4>
                </li>
                <li>
                  <h4
                    className="accountBar"
                    style={{ fontWeight: linkType == 1 ? "normal" : 300 }}
                    onClick={() => {
                      setLinkType(1);
                    }}
                  >
                    Devices
                  </h4>
                </li>
                <li>
                  <h4
                    className="accountBar"
                    style={{ fontWeight: linkType == 2 ? "normal" : 300 }}
                    onClick={() => {
                      setLinkType(2);
                    }}
                  >
                    Payment Details
                  </h4>
                </li>
                <li>
                  <h4
                    className="accountBar"
                    style={{ fontWeight: linkType == 3 ? "normal" : 300 }}
                    onClick={() => {
                      setLinkType(3);
                    }}
                  >
                    {user.type == "admin" ? "Institution Accounts" : ""}
                  </h4>
                </li>
              </ul>
            </div>
            <div className="log">
              <ThemeProvider theme={theme}>
                <div className="inlineButton">
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                      handleLogout();
                    }}
                    margin="small"
                    size="small"
                  >
                    LOGOUT
                  </Button>
                </div>
              </ThemeProvider>
            </div>
          </div>

          <div className="mobileLine">
            <Divider orientation="horizontal" />
          </div>
          <Divider orientation="vertical" flexItem />
          <div className="detailContent">
            <div
              className="personalDetails"
              style={{ display: linkType == 0 ? "inline" : "none" }}
            >
              {/* Personal Details */}

              <h1>Personal Details</h1>
              <div className="inputContainer">
                <div>
                  <TextField
                    id="first"
                    value={user.firstName}
                    label="First Name"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                </div>
                <div>
                  <TextField
                    id="last"
                    value={user.lastName}
                    label="Last Name"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    margin="normal"
                    fullWidth
                  />
                </div>
                <div>
                  <TextField
                    id="email"
                    value={user.email}
                    label="Email"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    margin="normal"
                    fullWidth
                  />
                </div>
              </div>
            </div>
            <div
              className="personalDetails"
              style={{ display: linkType == 0 ? "inline" : "none" }}
            >
              <h1>Subscription Details</h1>
              <div className="display-details">
                {"Type: "}
                {user.subType > 0
                  ? user.subType + " Month Package"
                  : "No Package Selected"}
              </div>
              <br />
              <div className="display-details">
                {"Package Ends: " + user.endDate}
              </div>
              <RenderSub />
              <br />
            </div>
            <div
              className="personalDetails"
              style={{ display: linkType == 0 ? "inline" : "none" }}
            >
              <h1>Delete Account</h1>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setDeleteAccount(true);
                }}
                margin="small"
                size="small"
              >
                Delete
              </Button>
              {deleteAccount && (
                <div>
                  <br />
                  <h3>ARE YOU SURE YOU WOULD LIKE TO DELETE YOUR ACCOUNT?</h3>
                  <br />
                  <div style={{ display: "flex" }}>
                    <div style={{ margin: "5px" }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={async () => {
                          console.log(getCookie("accessToken"));
                          await axios
                            .post(
                              `${BackendURL}/deleteUser`,
                              {},
                              {
                                headers: {
                                  authorization: `Bearer ${getCookie(
                                    "accessToken"
                                  )}`,
                                },
                              }
                            )
                            .then(async (res) => {
                              Cookies.remove("accessToken");
                              history.push({ pathname: "/deletesuccess" });
                              window.location.reload();
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                        margin="small"
                        size="small"
                        fullWidth
                      >
                        YES
                      </Button>
                    </div>
                    <div style={{ margin: "5px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setDeleteAccount(false);
                        }}
                        margin="small"
                        size="small"
                        fullWidth
                      >
                        NO
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Devices */}

            <div
              className="devices"
              style={{ display: linkType == 1 ? "inline" : "none" }}
            >
              <h1>Used Devices</h1>
              <div className="display-details">
                {"Device 1: "}
                {user.devices[0] ? user.devices[0] : "No Device Logged in"}

                <ThemeProvider theme={theme}>
                  <div className="inlineButton">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleDeviceLogout(0);
                      }}
                      margin="small"
                      size="small"
                      fullWidth
                    >
                      LOGOUT
                    </Button>
                  </div>
                </ThemeProvider>
                <br></br>
              </div>
              <div className="display-details">
                {"Device 2: "}
                {user.devices[1] ? user.devices[1] : "No Device Logged in"}
                <ThemeProvider theme={theme}>
                  <div className="inlineButton">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleDeviceLogout(1);
                      }}
                      margin="small"
                      size="small"
                      fullWidth
                    >
                      LOGOUT
                    </Button>
                  </div>
                </ThemeProvider>
              </div>
            </div>

            {/* Payment */}

            <div
              className="devices"
              style={{ display: linkType == 2 ? "inline" : "none" }}
            >
              <h1>Payment Method</h1>
              <div className="payment-text">
                {"Card Type: " +
                  user.cardType.charAt(0).toUpperCase() +
                  user.cardType.slice(1)}
              </div>
              <div className="payment-text">
                {"Card Number: " + user.cardNum}
              </div>

              <div className="payment-text">
                {"Expiry Date: " + user.expDate}
              </div>

              <RenderPayment />
            </div>

            {/* Admin */}

            <div
              className="devices"
              style={{
                display:
                  linkType == 3 && user.type == "admin" ? "inline" : "none",
              }}
            >
              <h1>Upload</h1>

              <input
                type="file"
                accept=".csv"
                style={{ marginTop: 20 }}
                onChange={(event) => {
                  console.log(event.target.files[0]);
                  setCurrentFile(event.target.files[0]);
                }}
              />

              <div style={{ width: "300px", marginTop: 20 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    if (currentFile) {
                      Papa.parse(currentFile, {
                        header: true,
                        complete: async function (results) {
                          setParsedFile(results.data);
                          setConfirmed(true);
                        },
                      });
                    }
                  }}
                  margin="small"
                  size="small"
                  fullWidth
                >
                  PREVIEW
                </Button>
              </div>
              {renderFile}
              <h3>{instText && instText}</h3>

              <h3>
                {creationError ? (
                  <div>
                    {/* <PDFDownloadLink
                      document={<MyDocument />}
                      fileName="results_institutional.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? "Loading document..." : "Click to see log!"
                      }
                    </PDFDownloadLink> */}
                    <br />
                    Complete - Please review log
                  </div>
                ) : (
                  ""
                )}
              </h3>

              {renderConfirm()}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
